.loadingView {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &:global(.fixed) {
    position: fixed;
    --loading-content-width: 20px;
    --loading-margin: calc(50% - var(--loading-content-width));
    &:global(.logo) {
      --loading-content-width: 46px;
    }
    &:global(.padding) {
      --loading-margin: calc(50% - var(--loading-content-width) - var(--page-margin) / 2);
    }
    left: var(--loading-margin);
    top: var(--loading-margin);
  }
}
