.chipsRow {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  div {
    text-transform: capitalize;
    border: 1px solid var(--underlay);
    :global(.MuiChip-label) {
      padding-left: 10px;
      padding-right: 10px;
    }
    &:global(.active) {
      background-color: var(--default-button-background);
      color: white;
      border: none;
    }
  }
}

@media only screen and (max-width: 1260px) {
  .chipsRow {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
