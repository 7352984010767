.errorCardContainer {
  padding: 5% 0;
  img {
    width: 35%;
  }
  :global(.caption) {
    padding-top: 4%;
    color: var(--caption-light);
  }
}
