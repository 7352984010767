.sectionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  :global(.label) {
    font-weight: 500;
    color: var(--text);
    font-size: 14px;
    margin-bottom: 8px;
  }
}
