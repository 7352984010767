.courseSections {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.courseSectionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.courseTermLabel {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 4px;
}
.courseSectionCard {
  padding: 12px;
  background-color: var(--background-secondary);
  border-radius: 12px;
  margin-top: 12px;
}
.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4px;
  & > button :global(.MuiSvgIcon-root) {
    font-size: 20px;
    color: var(--text);
  }
}
.sectionDetail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sectionDetailItem {
  min-width: 50%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  svg {
    margin-right: 6px;
    color: var(--text);
    opacity: 0.7;
    font-size: 16px;
  }
}
