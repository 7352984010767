.searchDropdown {
  --searchDropdown-width: 370px;
  height: fit-content;
  flex-direction: column;
  margin: 10px 0;
  align-items: flex-end;
  z-index: 2;
  :global(.searchPanel) {
    --searchPanel-width: calc(var(--searchDropdown-width) - 2px); // 2px for border
    border-radius: 0;
    border-top: 0;
    :global(.searchPanelInputContainer) {
      display: none;
    }
    :global(.MuiDivider-root) {
      &:first-of-type {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1260px) {
  .searchDropdown {
    --searchDropdown-width: 100vw;
    margin-left: auto;
    :global(.searchPanel) {
      display: initial;
      top: 0;
    }
  }
  .active {
    width: 100vw;
    overflow-x: hidden;
    height: fit-content;
    margin-left: initial;
    background-color: var(--surface);
  }
}
