.retracted {
  max-height: 580px;
}

.courseCard {
  position: relative;
  overflow: hidden;
  :global(.caption) {
    margin: 0;
    overflow-wrap: anywhere;
    line-height: var(--line-height-caption);
  }
  &.concise {
    padding: var(--card-padding);
    overflow: initial;
    & > .courseCardHeader {
      align-items: center;
      .courseCardTitleContainer {
        margin-right: auto;
        width: 100%;
      }
      & .badge {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

.termSwitchBtn {
  width: fit-content;
}

.gradeRow {
  height: 46px;
  column-gap: 4px;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 530px; // to display all grades
  // Styles for overall indicator
  & div:first-child {
    padding: 0;
  }
  // inner
  > div:first-child > div {
    margin: -10px 6px -10px 0px !important;
    height: 50px;
    width: 40px;
    border-radius: 0;
  }
  > div:nth-child(2) {
    padding-left: 0;
  }
  &.gradeRowConcise {
    min-width: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    > div:nth-child(2) {
      padding-left: 10px;
    }
  }
}

.courseLinkContainer {
  font-size: initial;
  width: fit-content;
}

.requirementSection {
  width: 100%;
}

.subHeadingContainer {
  width: 100%;
}

.courseCardTitleContainer {
  margin-right: 8px;
}

.courseCardHeader {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  button {
    width: 40px;
    height: 40px;
    color: var(--text);
  }
  :global(.active) {
    color: var(--primary);
  }
  :global(.title) {
    margin: 0 6px 0 0;
  }
}

.reviewsLinkHeading {
  cursor: pointer;
  svg {
    margin-left: 6px;
  }
}

@media only screen and (max-width: 1260px) {
  .gradeRow {
    overflow: initial;
    min-width: initial;
  }
}
