.logOutRow {
  width: calc(100% - 24px);
  padding: 12px 0 12px 14px;
  height: 32px;
  :global(.title) {
    font-weight: normal;
    font-size: 16px;
  }
}

.dialogDescription {
  height: 36px;
  font-size: 14px;
}

.globalModalContainer {
  :global(.MuiDialog-paper) {
    min-width: 280px;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1260px) {
  .issueChipsRow {
    flex-wrap: wrap;
  }
}
