.searchLoading {
  svg {
    color: var(--accent);
  }
}

.searchPanel {
  --searchPanel-width: 326px;
  width: var(--searchPanel-width);
  min-width: var(--searchPanel-width);
  border-right: 1px solid var(--border);
  position: sticky;
  top: calc(var(--header-height) + var(--page-margin));
  left: 0;
  height: auto;
  align-self: flex-start;
  max-height: calc(100vh - var(--header-height) - 2 * var(--page-margin) - 2px);
  overflow-y: scroll;
  overflow-x: hidden;
  svg {
    color: var(--text);
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .searchPanelInputContainer {
    background-color: var(--surface);
    padding: 6px 16px;
    position: sticky;
    top: 0;
    z-index: 2;
    align-items: center;
  }
  .recentChips {
    margin: 0 var(--card-padding) 6px var(--card-padding);
    width: fit-content;
  }
}

.searchPanelInputContainer {
  .goBackBtn {
    margin-left: -4px;
    height: 30px;
    width: 30px;
  }
}

.goBackBtn,
.searchIcon {
  margin-right: 6px;
}

.searchForm {
  display: flex;
  flex: 1;
}

.search-input {
  flex: 1;
}

.codeList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: hidden;
}

.searchListItem {
  height: auto;
  justify-content: space-between;
  position: relative;
  flex: 1;
  :global(.list-item-title-container) {
    margin-left: 24px;
  }
  :global(.list-item-color-bar) {
    position: absolute;
  }
}

@media only screen and (max-width: 1260px) {
  .searchPanel {
    display: none;
    border-right: 0;
  }
}
