.badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 30px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.badgeText {
  text-transform: capitalize;
  padding: 6px;
}
.badgeTextValue {
  padding: 0 4px;
  border-color: "white";
  border-left: 1px solid white;
  color: white;
}
