.showMoreOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 102px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.18) 0,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 3;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.showMoreLabel {
  margin-top: 80px;
  cursor: pointer;
  color: var(--primary);
  justify-content: center;
  font-size: 14px;
}

@media (prefers-color-scheme: dark) {
  .showMoreOverlay {
    background-image: linear-gradient(to bottom, rgba(31, 31, 31, 0.2) 0, var(--surface) 100%);
  }
}
