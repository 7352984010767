.gradeRow {
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: var(--background-secondary);
  border-radius: var(--border-radius);
  flex-wrap: wrap;
  svg {
    color: #3b3b3bbf;
  }
}

.reviewsFilterGradeIndicator {
  border-radius: 8px;
  height: auto;
  width: auto;
  padding: 2px 6px;
  min-width: 28px;
  font-size: 14px;
}

.ratingContainer {
  cursor: default;
  padding: 0 4px;
  height: 30px;
}

.reviewsFilterLabel {
  margin: 0 10px 0 0;
}

.concise {
  margin-bottom: var(--card-padding);
  height: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    flex: 1;
    padding: 0 10px;
    justify-content: space-between;
    margin: 6px;
  }
  > div:first-child {
    display: none;
  }
}
