.headerBgContainer {
  background-color: var(--surface);
  position: sticky;
  top: 0;
  z-index: 10;
  max-height: var(--header-height);
}

.headerContainer {
  --searchDropdown-width: 370px;
  height: var(--header-height);
  min-height: var(--header-content-height);
  justify-content: space-between;
  max-width: calc(var(--max-width) - 2 * var(--page-margin));
  margin-left: auto;
  margin-right: auto;
  & > .headerLogo {
    display: flex;
    align-items: center;
    color: var(--reversed-text);
    opacity: 1;
  }
}

.headerNav {
  margin-left: auto;
}

.navLabelContainer {
  &:first-of-type {
    margin-left: var(--card-padding);
  }
  svg {
    font-size: 24px;
    margin-bottom: 3px;
  }
  align-items: center;
  width: 86px;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.1s ease-in-out;
  text-transform: capitalize;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  &:global(.active) {
    opacity: 1;
    border-bottom: 2px solid var(--text);
  }
}

@media only screen and (max-width: 1260px) {
  .headerContainer {
    padding: 0 var(--card-padding);
    max-width: 100%;
    svg {
      color: var(--text);
    }
  }
  .headerSearchDropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: fit-content;
    margin-left: auto;
    .search-input {
      visibility: hidden;
    }
  }
  .headerLogo {
    margin-left: 12px;
  }
}
