.logoContainer {
  display: flex;
  align-items: center;
  font-family: "Pacifico", cursive;
  font-size: 22px;
  letter-spacing: 2px;
  cursor: pointer;
  color: var(--text);
  &.shine {
    font-size: 30px;
    animation: shine 2s infinite;
  }
}

@keyframes shine {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.725;
  }
  100% {
    opacity: 0.9;
  }
}
