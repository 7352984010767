.snackbarContainer {
  position: fixed;
  z-index: 2000;
  bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  right: 2.5%;
  left: 2.5%;
  align-items: center;
  :global(.MuiAlert-action) {
    padding: 0 0 0 16px;
  }
}
