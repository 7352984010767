.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkContainer {
  svg {
    margin-right: 8px;
  }
}
